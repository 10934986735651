$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}

@mixin min-w-1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin min-w-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}


.slider {
  padding: 0;
  max-width: 300px;
  width: 100%;
}

/*Range Reset*/
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
  --value: attr(value); /* Use the current value */
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: $color-white-20;
  border-radius: 100px;
  height: 12px;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -10px; /* Centers thumb on the track */
  background-color: $color-white;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

input[type='range']:focus::-webkit-slider-thumb {
  border: none;
  outline: none;
  outline-offset: 0;
}

/******** Firefox styles ********/
/* slider track */
input[type='range']::-moz-range-track {
  background-color: $color-white-20;
  border-radius: 100px;
  height: 12px;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  margin-top: -4px; /* Centers thumb on the track */
  background-color: $color-white;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

input[type='range']:focus::-moz-range-thumb {
  border: none;
  outline: none;
  outline-offset: 0;
}

/******** Firefox Focus Styles ********/
input[type='range']:focus::-moz-range-track {
  border: none;
  outline: none;
  outline-offset: 0;
}

/********** Internet Explorer styles ***********/
/* slider track */
input[type='range']::-ms-track {
  border: none;
  color: transparent;
  background-color: $color-white-20;
  border-radius: 100px;
  height: 12px;
}

/* slider thumb */
input[type='range']::-ms-thumb {
  border: none; /*Removes extra border that FF applies*/
  margin-top: -4px; /* Centers thumb on the track */
  background-color: $color-white;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

/* slider value */
input[type='range']::-ms-fill-lower,
input[type='range']::-ms-fill-upper {
  background-color: $color-white;
  border-radius: 100px;
}

input[type='range'] {
  background: linear-gradient(
    to right,
    $color-white 0%,
    $color-white calc(var(--value) * 1%),
    $color-white-20 calc(var(--value) * 1%),
    $color-white-20 100%
  );
}
