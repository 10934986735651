$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}

@mixin min-w-1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin min-w-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}


.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: relative;

  .sliderWrapper {
    display: flex;
    position: relative;
    z-index: 2;
    margin: 16px 16px 0 16px;

    img {
      max-width: 100%;
      margin-left: -8px;
    }

    .arrowContainer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-white;
      border-radius: 100%;
      padding: 8px;
      border: 1px solid $color-black-20;

      .arrow {
        align-self: center;
        color: $color-black-100;
        width: 32px;
        height: 32px;
      }
    }

    div:first-child {
      transform: rotate(90deg);
      top: 50%;
    }

    div:last-child {
      right: 0;
      top: 50%;
      transform: rotate(-90deg);
    }
  }

  .title {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 130%;
  }

  .contentWrapper {
    background-color: $color-black-60;
    margin: -64px 24px 0;
    white-space: pre-line;
    padding: 48px 12px;
    border-radius: 16px;

    .description {
      margin-top: 16px;
      text-align: center;
      line-height: 130%;
      font-size: 14px;
    }
  }
}

.secondRoot {
  justify-content: flex-start;

  img {
    max-width: 70% !important;
    margin: 0 auto !important;
  }

  .title {
    margin-bottom: 8px;
  }

  .contentWrapper {
    margin: 0 24px;
    padding: 12px;
    position: relative;

    .arrow {
      position: absolute;
      z-index: 3;
      max-width: 46% !important;
      right: -11%;
      top: 26%;
      transform: rotate(9deg);
    }

    .descriptionWrap {
      display: flex;
      align-items: center;

      img {
        max-width: 20% !important;
        margin-left: -16px !important;
      }

      .description {
        margin: 0;
        text-align: start;
      }
    }
  }

  .screenshotsContainer {
    display: flex;
    position: relative;
    margin-top: 32px;
    height: 100%;

    img {
      margin: 0 !important;
      border-radius: 8px;
      position: absolute;
      width: 40% !important;
      height: 70% !important;
    }

    img:nth-child(1) {
      transform: rotate(-6deg);
    }

    img:nth-child(2) {
      left: 29%;
      z-index: 1;
      top: -3%;
      box-shadow: 0 0 1px 1px $color-black-60;
    }

    img:nth-child(3) {
      right: 0;
      transform: rotate(6deg);
    }

  }
}

.skipBtn {
  position: absolute;
  width: auto;
  top: 2%;
  right: 0;
  z-index: 9999;
}
