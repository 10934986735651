$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}

@mixin min-w-1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin min-w-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}


.transactionCard {
    padding: 16px;
    margin-bottom: 16px;
    background: #043e7fc9;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
        margin-bottom: 0;
    }
}

.transactionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.transactionInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.date {
    color: rgba(255, 255, 255, 0.6);
}

.explorerLink {
    padding: 0;

    span {
        color: #4A9EFF;
        font-size: 14px;
    }
}

.claimButton {
    margin-top: 12px;
}

.statusSuccess {
    color: #4CAF50;
}

.statusPending {
    color: #FFC107;
}

.statusReadyToClaim {
    color: #2196F3;
}

.statusRejected {
    color: #F44336;
}




.typeContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.infoIcon {
    cursor: pointer;
    width: 30px;
    height: 30px;
}



.modalInfoRoot {
    // padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoRow {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .value {
        word-break: break-all;
        opacity: 0.8;
    }
}


// amount
.amountWithToken {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tokenIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

// announcements
.campaignDetails {
    background: rgba(4, 62, 127, 0.3);
    border-radius: 8px;
    padding: 12px;
    margin: 12px 0;
}

.timeRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
        margin-bottom: 0;
    }
}

// unstakeinfo
.unstakeInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    padding: 12px;
    background: rgba(4, 62, 127, 0.3);
    border-radius: 8px;
}