$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}

@mixin min-w-1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin min-w-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}


.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;
    overflow: hidden;
}

.balanceRoot {
    border-radius: 20px;
    background-color: $color-black;
    width: 100%;
    padding: 15px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 125px;
    position: relative;

    h2 {
        width: 200px;
        text-align: center;
        position: relative;
        z-index: 3;
        text-shadow: 2px 2px 4px $color-black;
    }
}

.bobe {
    position: absolute;
    right: -30px;
    bottom: 0px;
    max-width: 180px;
    z-index: 2;
}

.stars {
    position: absolute;
    left: 11px;
    z-index: 1;
}




.statsContainer {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.statsBlock {
    background: rgba(13, 33, 51, 0.5);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.claimButton {
    margin-top: 12px;
}

@media (min-width: 1024px) {
    .statsBlock {
        align-items: flex-start;
    }
}






.history {
    padding: 16px;
    background: #fff3;
    border-radius: 16px;
}

.loading,
.empty {
    text-align: center;
    padding: 32px 16px;
    color: rgba(255, 255, 255, 0.6);
}

.history .loading,
.loadingMoreButton {
    // margin-top: 16px;
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreButton {
    background: #004aeb;
    width: auto;

    span {
        color: white;
    }
}

.tabs {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;

    .tabButton {
        border-radius: 100px;
        // min-width: 20%;
        width: 100%;
        text-align: center;
        
        padding: 16px;
    }
}